"use client";

import Space from "antd/lib/space";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { login } from "../services/auth";

const LoginPage = () => {
  const router = useRouter();
  useEffect(() => {
    const backParam = new URLSearchParams(location.search).get("back");
    login()
      .then(() => {
        router.push(backParam ?? "/");
      })
      .catch((e) => {
        console.error(e);
        router.push(backParam ?? "/");
      });
  }, []);
  return (
    <Space
      direction="vertical"
      align="center"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Typography.Title level={1}>מחברים אותך...</Typography.Title>
      <Spin size="large" />
    </Space>
  );
};
export default LoginPage;
